import React from 'react'
import AuthHeader from '../Headers/AuthHeader'
import AuthNavbar from '../Navbars/AuthNavbar'

import AuthFooter from '../Footers/AuthFooter'
import { Card, Col, Container, Row } from 'reactstrap'

export default function PublicLayout({children}) {
  return (<>
  <div className='layout-wrapper p-0'>
      <Row className='m-0'>
        <Col xs="3" className='sidebar-wrapper'>
          <AuthNavbar/>
        </Col>

        <Col xs="9" className='content-wrapper '>
          <AuthHeader />
          {children}
        </Col>
      </Row>
    </div>
    </>
  )
}
