import React, { useState } from "react";
import { Table, Button, Input } from "reactstrap";

const PaginatedTable = ({
  data,
  columns,
  currentPage,
  totalPages,
  onPageChange,
  limit,
  setLimit
}) => {
  const paginate = (pageNumber) => {
    onPageChange(pageNumber);
  };
  return (
    <>
      <Table className="align-items-center table-flush custom_table"  responsive>
        <thead className="thead-light">
          <tr>
            {columns?.map((col, index) => (
              <th key={index} scope="col" className="table_heading">
                {col.text}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data && data.length > 0 ? data?.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {columns.map((col, colIndex) => (
                <td key={colIndex} className="table-cell-wrap">
                  {col.formatter
                    ? col.formatter(row[col.dataField], row, rowIndex)
                    : row[col.dataField]}
                </td>
              ))}
            </tr>
          )) : <tr><td style={{textAlign:"center"}} colSpan={columns.length}>Data Not Found !</td></tr>} 
        </tbody>
      </Table>
      <div className="pagination justify-content-end p-3">
        <Button onClick={() => paginate(1)} disabled={currentPage === 1}>
          <i className="fas fa-angle-double-left"></i>
        </Button>
        {Array.from({ length: totalPages }, (_, index) => (
          <Button
            key={index}
            onClick={() => paginate(index + 1)}
            active={index + 1 === currentPage}
          >
            {index + 1}
          </Button>
        ))}
        <Button
          onClick={() => paginate(totalPages)}
          disabled={currentPage === totalPages}
        >
          <i className="fas fa-angle-double-right"></i>
        </Button>
      </div>
    </>
  );
};

export default PaginatedTable;
