import { useContext, useEffect, useRef, useState } from "react";
import AuthLayout from "../components/PublicLayout/authLayout";
import { useNavigate, Link, useLocation } from "react-router-dom";
import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  Button,
  Input,
  FormGroup,
  CardBody,
  InputGroup,
  InputGroupAddon,
  Form,
} from "reactstrap";
import PaginatedTable from "../components/Table/PaginatedTable";
import APIServicenew from "../utils/APIGeneralService";
import { UserContext } from "../context/UserContextProvider";
import "react-toastify/dist/ReactToastify.css";
import { capitalizeFirstLetter } from "../utils/Helper";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from "react-toastify";
import SMSTemplate from "../components/Modal/SMSTemplate";


const ScheduleMessage = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const messageData = location.state;
  const APIServices = new APIServicenew(process.env.REACT_APP_OTHER_API_URL);
  const authToken = process.env.REACT_APP_authToken;
  const { accessToken, logout } = useContext(UserContext);
  const headers = { "auth-token": authToken, authorization: accessToken };
  const [data1, setData1] = useState([]);
  const [templateList, setTemplateList] = useState([]);
  const [showDt, setShowDt] = useState(false);
  const [loading, setLoading] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [templateId, setTemplateId] = useState(0);
  const [sort, setSort] = useState("newest");
  const [mobileNumber, setMobileNumber] = useState(
    messageData?.mobile_number || ""
  );
  const [name, setName] = useState(messageData?.name || "");
  const [messageType, setMessageType] = useState(messageData?.type || "");
  const [scheduleDate, setScheduleDate] = useState(
    messageData && messageData?.schedule_date
      ? new Date(messageData?.schedule_date).toISOString().split("T")[0]
      : ""
  );
  const [yourMessage, setYourMessage] = useState(messageData?.message || "");
  const [errors, setErrors] = useState({});
  const [load, setLoad] = useState(false);
  const [countryCode, setCountryCode] = useState(messageData?.country_code || "+1"); // Default country code
  const { setAccessToken } = useContext(UserContext);
  const today = new Date().toISOString().split("T")[0];

  // common validation function
  const validate = () => {
    const errors = {};
    if (!mobileNumber) {
      errors.mobileNumber = "Recipient Number is required.";
    }
    if (!name) {
      errors.name = "Name is required.";
    }
    if (!messageType) {
      errors.messageType = "Select message type is required.";
    }
    if (!scheduleDate) {
      errors.scheduleDate = "Schedule Date is required.";
    }
    if (!yourMessage) {
      errors.yourMessage = "Message is required.";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  //handle submit details
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoad(true);
    // Check if the message object is defined (for editing)
    const messageId = messageData?.id; // Assuming 'message' is available in your component's scope
    //call common validation function
    if (validate()) {
      // Create the payload object
      let payload = {
        name: name,
        type: messageType,
        schedule_date: scheduleDate,
        country_code: countryCode,
        mobile_number: mobileNumber,
        message: yourMessage,
        template_id: templateId,
      };
      // Check if message.id exists, and if so, add it to the payload
      if (messageId) {
        payload.id = messageId; 
        
        try {
          //call API for edit message
          const res = await APIServices.post("editMessage", payload, headers);

          if (res?.data?.status === true) {
            showToast(res?.data?.message || "Success", "success");
            setTimeout(() => {
              navigate("/user/message");
            }, 1000);
          } else {
            showToast(res?.data?.message || "Something went wrong.", "error");
          }
        } catch (error) {
          showToast("An error occurred while editing the message.", "error");
          console.error("Error editing message:", error);
        }
      } else {
        try {
          //call API for add message
          const res = await APIServices.post("addMessage", payload, headers);

          if (res?.data?.status === true) {
            showToast(res?.data?.message || "Success", "success");
            setTimeout(() => {
              navigate("/user/message");
            }, 1000);
          } else {
            showToast(res?.data?.message || "Something went wrong.", "error");
          }
        } catch (error) {
          showToast("An error occurred while adding the message.", "error");
          console.error("Error adding message:", error);
        }
      }
      setLoad(false);
    } else {
      setLoad(false);
    }
  };

  //open sms templates
  const openTemplate = async () => {
    setShowDt(true)
  };

  //get text of template from object
  const getTemplateTextById = (id) => {
    const template = templateList.find(template => template.id === id);
    return template ? template.text : null; 
  };

  //handle choose sms template 
  const handleChooseTemplate = async () => {
    let templateText = getTemplateTextById(templateId);
    setYourMessage(templateText)
    setShowDt(false)
  };
  

  //show success and fail message
  const showToast = (message, type) => {
    toast(message, {
      position: "top-right",
      autoClose: 2000,
      type,
      hideProgressBar: false,
      closeOnClick: true,
    });
  };

  return (
    <AuthLayout>
      <div className="content-inner schedule-messaging">
        <div className="overflow-content">
          <Card className="shadow">
            <CardHeader className="border-0 bg-white-theme p-0">
              <Row className="align-items-center">
                <Col xs="3">
                  <h3 className="mb-0 card-title font_22">Send a message</h3>
                </Col>
                <Col xs="9" className="d-flex justify-content-end">
                  <Link
                    className="btn btn-primary btn-block font_16 primary-btn d-flex justify-content-center align-items-center"
                    to="/user/message"
                  >
                    <i class="fa-solid fa-arrow-left me-2"></i>
                    Back to Message
                  </Link>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Row>
                <Col xs="8">
                  <Form role="form" onSubmit={handleSubmit}>
                    <Row>
                      {/* First Column */}
                      <Col md="6">
                        <FormGroup className="mt-3">
                          <label
                            htmlFor="recipientNumber"
                            className="form-label"
                          >
                            Recipient Number
                          </label>
                          <InputGroup className="form_input d-flex align-items-center">
                            {/* Dropdown for country code */}
                            <InputGroupAddon addonType="prepend">
                              <Input
                                type="select"
                                className="form-control font_black_16 country-code"
                                value={countryCode}
                                onChange={(e) => setCountryCode(e.target.value)}
                                style={{ maxWidth: "120px" }} // Style for dropdown
                              >
                                <option value="+1">US +1</option>
                                <option value="+91">IN +91</option>
                                <option value="+44">UK +44</option>
                                <option value="+61">AUS +61</option>
                                {/* Add more country codes as needed */}
                              </Input>
                            </InputGroupAddon>

                            {/* Phone number input */}
                            <Input
                              className="form-control  font_black_16"
                              id="recipientNumber"
                              name="phoneNumber"
                              placeholder="Enter Phone No"
                              type="tel"
                              value={mobileNumber}
                              onChange={(e) => setMobileNumber(e.target.value)}
                              maxLength={10}
                              style={{ border: "none" }} // Align style with dropdown
                            />
                          </InputGroup>

                          {/* Error message for validation */}
                          {errors.mobileNumber && (
                            <div className="errorVal mt-2">
                              {errors.mobileNumber}
                            </div>
                          )}
                        </FormGroup>

                        <FormGroup className="mt-3">
                          <label htmlFor="name" className="form-label">
                            Name
                          </label>
                          <Input
                            className="form-control form_input font_black_16 mb-3"
                            id="name"
                            name="name"
                            placeholder="Enter Name"
                            type="text"
                            value={name}
                            maxLength={30}
                            onChange={(e) => setName(e.target.value)}
                          />
                          {errors.name && (
                            <div className="errorVal mt-2">{errors.name}</div>
                          )}
                        </FormGroup>
                      </Col>

                      {/* Second Column */}
                      <Col md="6">
                        <FormGroup className="mt-3">
                          <label htmlFor="messageType" className="form-label">
                            Send This Message As
                          </label>
                          <Input
                            type="select"
                            id="messageType"
                            name="messageType"
                            className="form-control form_input font_black_16 mb-3"
                            value={messageType}
                            onChange={(e) => setMessageType(e.target.value)}
                          >
                            <option value="">Select Message Type</option>
                            <option value="MMS">MMS</option>
                            <option value="SMS">SMS</option>
                          </Input>
                          {errors.messageType && (
                            <div className="errorVal mt-2">
                              {errors.messageType}
                            </div>
                          )}
                        </FormGroup>
                        <FormGroup className="mt-3">
                          <label htmlFor="scheduleDate" className="form-label">
                            Scheduled Date
                          </label>
                          <Input
                            className="form-control form_input font_black_16 mb-3"
                            id="scheduleDate"
                            name="scheduleDate"
                            type="date"
                            value={scheduleDate}
                            min={today}
                            onChange={(e) => setScheduleDate(e.target.value)}
                          />
                          {errors.scheduleDate && (
                            <div className="errorVal mt-2">
                              {errors.scheduleDate}
                            </div>
                          )}
                        </FormGroup>
                      </Col>

                      {/* Textarea for Message */}
                      <Col md="12">
                        <FormGroup className="mt-3">
                          <label htmlFor="yourMessage" className="form-label">
                            Your Message
                          </label>
                          <div className="d-flex gap-2 mb-3">
                            <Input
                              className="form-control form_input font_black_16 h-auto"
                              id="yourMessage"
                              name="yourMessage"
                              placeholder="Type here..."
                              type="textarea"
                              rows="5"
                              value={yourMessage}
                              onChange={(e) => setYourMessage(e.target.value)}
                            />
                            <div className="or_text"><span>or</span></div>
                            {templateId ? (
                              <span onClick={()=>openTemplate()} className="choose-template">Choose Another Template Message</span>
                            ) : (
                              <span onClick={()=>openTemplate()} className="choose-template">Choose a Template Message</span>
                            )}
                          </div>

                          {errors.yourMessage && (
                            <div className="errorVal mt-2">
                              {errors.yourMessage}
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                      {messageData?.id ? (
                        <Col md="12" className="text-start">
                          {load ? (
                            <Button className="btn primary-btn " disabled>
                              Processing...
                            </Button>
                          ) : (
                            <Button className="btn sky_btn" type="submit">
                              Update Now
                            </Button>
                          )}
                        </Col>
                      ) : (
                        <Col md="12" className="text-start">
                          {load ? (
                            <Button className="btn primary-btn " disabled>
                              Processing...
                            </Button>
                          ) : (
                            <Button className="btn sky_btn" type="submit">
                              Schedule Now
                            </Button>
                          )}
                        </Col>
                      )}
                    </Row>
                  </Form>
                </Col>
                <Col xs="4"></Col>
              </Row>
            </CardBody>
          </Card>
        </div>
      </div>
      <SMSTemplate
        showdt={showDt}
        handleCloseDt={() => setShowDt(false)}
        setTemplateId={setTemplateId}
        setTemplateList={setTemplateList}
        handleChooseTemplate={handleChooseTemplate}
      />

    </AuthLayout>
    
  );
};

export default ScheduleMessage;
