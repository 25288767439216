import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { setCookies } from "../utils/Helper";
import { UserContext } from "../context/UserContextProvider";
import { Link } from 'react-router-dom';
//import toast from "react-hot-toast";
import {
  Button
} from "reactstrap";
import PublicLayout from '../components/PublicLayout/publicLayout';

const ForgetPassword = () => {
  const navigate = useNavigate();


  return (
    <PublicLayout>
      <section className="login_page_inner oborna_login_sec ">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-12 col-md-7">
              <div className="login-form psw-recovery">
                <div className="text-center top">
                  <img src="/images/oborna.svg" className="img-fluid mb-5" alt="logo" />
                  <h1 className="login-heading text-center font_24 font_700">Password Recovery</h1>
                  <p className="login-subheading text-center font_12 font_400 mb-3">
                    To restore password, you can send an Email for<br /> OTP Verification
                  </p>
                </div>
                <div className="text-center">
                  <Link className="font_12 font_700" to="/forgot-password">
                    <Button className="btn sent_btn font_black_14 w-100 py-2 font_700" type="button">
                      Send Email
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </PublicLayout>

  );
};

export default ForgetPassword;
